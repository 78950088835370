import type { AppProps } from 'next/app';

import '@/ui-kit/styles/variables.css';
import '@/ui-kit/styles/default.css';

import FirebaseProvider from '@/providers/firebase';

const App = ({ Component, pageProps }: AppProps) => (
  <FirebaseProvider>
    <Component {...pageProps} />
  </FirebaseProvider>
);

export default App;
